/* Custom Styles */
body {
    background: linear-gradient(135deg, #0a1f3d, #1e3c72);
    color: #fff;
    font-family: 'Arial', sans-serif;
    margin: 0;
    line-height: 1.6;
}

/* Header and Navigation */
.header {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    padding: 15px 0;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.navbar-brand img {
    height: 40px;
}

.navbar-nav .nav-link {
    color: #fff !important;
    font-weight: 500;
    transition: color 0.3s ease;
    margin: 0 10px;
    padding: 8px 15px;
    border-radius: 5px;
}

.navbar-nav .nav-link:hover {
    color: #007bff !important;
    background: rgba(255, 255, 255, 0.1);
}

/* Mini Profile Dropdown */
.navbar-nav .dropdown-menu {
    background: rgba(0, 0, 0, 0.8);
    border: 1px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
}

.navbar-nav .dropdown-item {
    color: #fff !important;
    transition: background 0.3s ease;
    padding: 10px 20px;
}

.navbar-nav .dropdown-item:hover {
    background: rgba(255, 255, 255, 0.1);
}

/* Main Content */
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

/* Cards */
.card {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px;
}

.card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

.card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.card-body {
    padding: 20px;
}

.card-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: #fff;
}

.card-text {
    color: #ddd;
    font-size: 1rem;
    margin-bottom: 20px;
}

.btn-primary {
    background: #007bff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    color: #fff;
    transition: background 0.3s ease;
}

.btn-primary:hover {
    background: #0056b3;
}

.btn-secondary {
    background: #6c757d;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    color: #fff;
    transition: background 0.3s ease;
}

.btn-secondary:hover {
    background: #5a6268;
}

/* Detail View */
.detail-view {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
}

.detail-view img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 20px;
}

.detail-view h2 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 20px;
    color: #fff;
}

.detail-view p {
    color: #ddd;
    font-size: 1.1rem;
    line-height: 1.8;
}

/* Footer */
.footer {
    background: rgba(0, 0, 0, 0.3);
    padding: 20px 0;
    text-align: center;
    margin-top: 40px;
}

.footer a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
    margin: 0 10px;
}

.footer a:hover {
    color: #007bff;
}

.footer .social-links a {
    font-size: 1.2rem;
}

/* Animations */
@keyframes fadeInDown {
    from { opacity: 0; transform: translateY(-50px); }
    to { opacity: 1; transform: translateY(0); }
}

@keyframes fadeInUp {
    from { opacity: 0; transform: translateY(50px); }
    to { opacity: 1; transform: translateY(0); }
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

/* Responsive Design */
@media (max-width: 1200px) {
    .container {
        padding: 15px;
    }

    .card img {
        height: 180px;
    }

    .detail-view h2 {
        font-size: 2rem;
    }

    .detail-view p {
        font-size: 1rem;
    }
}

@media (max-width: 992px) {
    .navbar-nav .nav-link {
        margin: 5px 0;
    }

    .card {
        margin-bottom: 15px;
    }

    .card img {
        height: 160px;
    }

    .detail-view h2 {
        font-size: 1.8rem;
    }

    .detail-view p {
        font-size: 0.95rem;
    }
}

@media (max-width: 768px) {
    .navbar-nav .nav-link {
        margin: 5px 0;
    }

    .card {
        margin-bottom: 15px;
    }

    .card img {
        height: 150px;
    }

    .detail-view h2 {
        font-size: 1.5rem;
    }

    .detail-view p {
        font-size: 0.9rem;
    }
}

@media (max-width: 576px) {
    .navbar-brand img {
        height: 30px;
    }

    .navbar-nav .nav-link {
        padding: 5px 10px;
    }

    .card img {
        height: 120px;
    }

    .card-title {
        font-size: 1.2rem;
    }

    .card-text {
        font-size: 0.85rem;
    }

    .detail-view h2 {
        font-size: 1.2rem;
    }

    .detail-view p {
        font-size: 0.8rem;
    }

    .footer .social-links a {
        font-size: 1rem;
    }
}

/* Custom Logo */
.logo-text {
    color: #007bff;
    font-weight: bold;
    font-size: 1.5rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.logo-text::after {
    content: "Hunegna";
    color: #FFD700;
    margin-left: 5px;
}

/* Login Box */
.login-box {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: 20px;
    backdrop-filter: blur(10px);
}

/* Modal Styling */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 600px;
    width: 90%;
    position: relative;
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

/* Detail View Styling */
.detail-view {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 15px;
    padding: 30px;
    margin-top: 20px;
    backdrop-filter: blur(10px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.detail-view img {
    width: 100%;
    height: auto;
    border-radius: 15px;
    margin-bottom: 25px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.detail-view h2 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 25px;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.detail-view p {
    color: #ddd;
    font-size: 1.1rem;
    line-height: 1.8;
    margin-bottom: 20px;
}

.detail-view ol,
.detail-view ul {
    margin-bottom: 20px;
    padding-left: 20px;
}

.detail-view li {
    margin-bottom: 10px;
    color: #ddd;
}

.detail-view h3 {
    font-size: 1.8rem;
    font-weight: 600;
    margin-top: 25px;
    margin-bottom: 15px;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.detail-view strong {
    color: #fff;
    font-weight: 600;
}

.detail-view .btn-secondary {
    background: #6c757d;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    color: #fff;
    transition: background 0.3s ease;
    margin-bottom: 25px;
}

.detail-view .btn-secondary:hover {
    background: #5a6268;
}

/* Custom Content Styling */
.custom-content {
    color: #ddd;
    font-size: 1.1rem;
    line-height: 1.8;
}

.custom-content h2,
.custom-content h3 {
    color: #fff;
    margin-top: 25px;
    margin-bottom: 15px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.custom-content p {
    margin-bottom: 20px;
}

.custom-content ol,
.custom-content ul {
    margin-bottom: 20px;
    padding-left: 20px;
}

.custom-content li {
    margin-bottom: 10px;
}

.custom-content img {
    width: 100%;
    height: auto;
    border-radius: 15px;
    margin-bottom: 25px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}